@import '../common/variables';
/* stylelint-disable */
.bcow-tooltip {
    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
        color: $white;
        text-decoration: underline;
    }
}

.bcow-menu-item-description {
    a,
    a:hover,
    a:active,
    a:visited,
    a:focus {
        color: $bdl-gray;
        text-decoration: underline;
    }
}
/* stylelint-enable */
