@import '../common/variables';

.be.bcow {
    font-family: $font;

    .btn {
        padding: 3px 13px 3px 10px;
    }

    .btn-content {
        display: flex;
        align-items: center;
    }

    .bcow-integration-icon {
        padding: 3px;
    }
}

.bcow-menu {
    .bcow-menu-item-title {
        margin: 0;
        overflow: hidden;
        color: $bdl-gray;
        font-weight: bold;
        text-overflow: ellipsis;
    }

    .bcow-menu-item-description {
        max-width: 200px;
        margin: 0;
        color: $bdl-gray-62;
        font-size: 11px;
        line-height: 14px;
        white-space: normal;
        word-wrap: break-word;
    }
}

.bcow-portal-container {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;

    .bcow-portal-loading-indicator {
        div {
            // Platform standard color
            background-color: $bdl-gray;
        }
    }

    .error-mask {
        max-width: 650px;
        margin: 0 auto;
        font-size: 16px;
    }
}
