@import '../../../styles/variables';
@import '../../../styles/common/_animations';
@import '../mixins';

.bdl-AdditionalTab {
    @include bdl-SidebarNavButton;

    &:hover {
        background-color: $bdl-gray-05;

        svg path {
            fill: $bdl-gray-80;
        }
    }

    &.bdl-is-disabled {
        opacity: .5;
    }

    &.bdl-is-hidden {
        display: none;
    }
}

.bdl-AdditionalTab-icon {
    @include bdl-fadeIn(.5s);

    width: 24px;
    height: 24px;
}
