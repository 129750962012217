// DEPRECATED VALUES
// DO NOT USE IN NEW CODE

@import './constants/colors';

// src/styles/_variables.scss
$box-blue: $bdl-box-blue !default;
$primary-color: $bdl-box-blue !default;
$koray-ing: #2a62b9 !default;
$picton-blue: #3392ec !default;
$hover-blue-background: #f6fafd !default;
$hover-blue-border: #d9e3e9 !default;
$selected-blue-background: #ecf4fc !default;
$selected-blue-border: #bfd4df !default;
$bdl-secondary-blue: $bdl-dark-blue !default;
$bdl-neutral-01: $bdl-gray !default;
$bdl-neutral-02: $bdl-gray-62 !default;
$bdl-neutral-03: $bdl-gray-50 !default;
$bdl-neutral-04: $bdl-gray-10 !default;
$bdl-neutral-05: $bdl-gray-05 !default;
$bdl-neutral-06: $bdl-gray-02 !default;
$baby-blue: #bed4de !default;
$blue-purple: #6d83ff !default;
$hover-blue: #48b6f3 !default;
$dusty-plum: #9b59b6 !default;
$sad-sunjin: #72388f !default;
$pale-mint: #eefbf5 !default;
$thats-mint: $bdl-green-light !default;
$shady-acres: #15aa61 !default;
$wild-rice: #ede097 !default;
$early-dawn: #fffbe5 !default;
$lemon-meringue: #ffd522 !default;
$dehydrated: $bdl-yellorange !default;
$kumera: #81721b !default;
$mad-max: #f39c12 !default;
$ayers-rock: #e06e2b !default;
$amaranth: $bdl-watermelon-red !default;
$lavender-blush: #feeff2 !default;
$carissma: #eb8b9c !default;
$great-balls-of-fire: #ef4836 !default;
$king-crimson: #c82341 !default;
$bfive: #b5b5b5 !default;
$dtwo: #d2d2d2 !default;
$efive: #e5e5e5 !default;
$ffive: #f5f5f5 !default;
$fnine: #f9f9f9 !default;
$seesee: #ccc !default;
$haze: #fcfcfc !default;
$off-white: $bdl-neutral-04 !default;
$sf-fog: #bdc3c7 !default;
$karl-fog: #fbfbfc !default;
$silver-chalice: #afafaf !default;
$storms-a-comin: #737f8b !default;
$blue-steel: #2c3e50 !default;
$better-black: #494949 !default;
$downtown-grey: #999ea4 !default;
$twos: $bdl-gray !default;
$fours: #444 !default;
$sevens: #777 !default;
$eights: #888 !default;
$nines: #999 !default;
$eees: #eee !default;
$tendemob-grey: #64686d !default;
$sunset-grey: #464a4f !default;
$seventy-sixers: $bdl-gray-62 !default;
$approx-mischka-grey: #a3adb9 !default;
$lightest-charcoal: #c4c4c4;
$purple-rain: $bdl-purple-rain;

// src/elements/common/_variables.scss SOLID COLORS
$blue: #0061d5 !default;
$light-blue: #e4f4ff !default;
$grey-blue: #bed4de !default;
$dark-cerulean: #1a5976 !default;
$white: #fff !default;
$black: #000 !default;
$threes: #333 !default;
$fivef: #f5f5f5 !default;
$almost-white: #fcfcfc !default;
$red: #c82341 !default;
$see-see: #ccc !default;
$grey: #bdc3c7 !default;

// src/elements/common/_variables.scss TRANSPARENT COLORS
$transparent-black: rgba($threes, .04) !default;
$lightest-black: rgba($threes, .1) !default;
$lighter-black: rgba($threes, .2) !default;
$darker-black: rgba($threes, .4) !default;
$lighter-white: rgba($white, .9) !default;
$lighter-grey-blue: rgba($grey-blue, .4) !default;
$lighter-light-blue: rgba($light-blue, .4) !default;
