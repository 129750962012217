@import '../../styles/variables';

.contact-data-list-item {
    display: flex;
    align-items: center;
    padding: $bdl-grid-unit * 2;
    line-height: 16px;
}

.contact-avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: $bdl-grid-unit * 2;
}

.contact-name-container {
    overflow: hidden;
}

.contact-name {
    color: $bdl-gray-80;
    font-size: 13px;
}

.contact-sub-name {
    color: $bdl-gray-62;
    font-size: 13px;
}

.contact-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
