.bcu-item-action {
    width: 24px;
    height: 24px;

    .crawler {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    button {
        display: flex;

        // Vertically center icon
        svg {
            display: block;
        }

        .be-icon-in-progress {
            width: 24px;
            height: 24px;

            // Hide cross icon without hover
            svg {
                display: none;
            }
        }

        // Display cross icon on hover and hide loading indicator
        &:hover .be-icon-in-progress {
            svg {
                display: block;
            }

            .crawler {
                display: none;
            }
        }
    }
}
