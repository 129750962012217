/**************************************
 * Inline Notifications
 **************************************/

.inline-alert {
    display: none;
    margin: 10px 0;
    padding: 14px 10px;
    color: $bdl-gray;
    border-radius: 4px;

    &.inline-alert-visible {
        display: block;
    }

    &.inline-alert-warning {
        background-color: $bdl-yellorange-10;
        border: 1px solid $bdl-yellorange-50;
    }

    &.inline-alert-error {
        background-color: $bdl-watermelon-red-10;
        border: 1px solid $bdl-watermelon-red-50;
    }

    &.inline-alert-success {
        background-color: $bdl-green-light-10;
        border: 1px solid $bdl-green-light;
    }

    &.inline-alert-info {
        background-color: $bdl-light-blue-10;
        border: 1px solid $bdl-light-blue-50;
    }

    &.inline-alert-generic {
        background-color: $bdl-gray-10;
        border: 1px solid $bdl-gray-50;
    }
}
