@import '../../styles/variables';

/**************************************
 * Error Mask
 **************************************/

.error-mask {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    overflow: hidden;
    border: 1px dashed $bdl-gray-50;
    border-radius: 3px;

    .error-mask-sad-cloud {
        margin-bottom: 20px;
    }

    h4 {
        margin-top: -10px;
    }

    h4,
    h5 {
        width: 100%; // IE11 Bug with flexbox
        margin-bottom: 0;
        color: $bdl-gray-62;
        text-align: center;
    }
}
