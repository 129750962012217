@import '../variables';

.be-logo {
    padding-left: 20px;

    .be-logo-custom {
        max-width: 80px;
        max-height: 32px;

        .be-is-small & {
            max-width: 75px;
        }
    }

    .be-logo-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 32px;
        background-color: $bdl-gray-10;
        border: 1px dashed;

        .be-is-small & {
            width: 60px;
        }

        span {
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    svg {
        display: block;
    }
}
