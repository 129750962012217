@import '../../styles/variables';

/**************************************
 * Search Form
 **************************************/

.search-input-container {
    display: inline-block;
    min-height: 32px;

    .action-button {
        display: flex;
        align-items: center;
        width: 30px;
        padding: 0;
        background: none;
        border: 0;
        cursor: pointer;

        &:hover,
        &:focus {
            outline: none;
        }

        path {
            transition: fill linear .1s;
        }

        svg {
            flex: 1;
        }
    }

    .action-buttons,
    .search-form-loading-indicator {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
    }

    .action-buttons {
        right: 0;
        align-items: stretch;
    }

    .search-form-loading-indicator {
        right: 10px;
        align-items: center;
    }

    .clear-button {
        display: none;

        path:first-of-type {
            fill: $bdl-gray-40;
        }
    }

    .search-form.use-clear-button {
        .clear-button {
            display: flex;
        }

        .search-button {
            display: none;
        }

        &.is-empty {
            .clear-button {
                display: none;
            }

            .search-button {
                display: flex;
            }
        }
    }

    .search-input {
        padding-right: 30px;
    }

    .search-input:focus ~ .clear-button {
        path:first-of-type {
            fill: $bdl-gray-80;
        }
    }

    .search-input:focus + .search-button {
        path {
            fill: $bdl-gray-80;
        }
    }

    .search-form {
        position: relative;
        display: flex;
        align-items: stretch;
    }
}
