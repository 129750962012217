@import '../../styles/variables';
@import '../../styles/mixins/overlay';
@import 'PillSelector';

.bdl-PillSelector {
    &.show-error {
        border-color: $bdl-watermelon-red;
    }
}

.bdl-PillSelectorDropdown {
    .overlay {
        @include bdl-Overlay-container;
    }

    .suggested {
        padding-top: 0;
    }
}

/*
* Section below is for bdl-namespace backwards compatibility.
* Do not add changes below this line.
*/
.pill-selector-input-wrapper {
    @extend .bdl-PillSelector;
}
