@import '../../styles/variables';

/**************************************
 * Label
 **************************************/

.bdl-Label {
    display: block;
    color: $bdl-gray-62;
    font-weight: bold;

    .bdl-Label-optional,
    .label-optional {
        padding-left: 3px;
    }

    .tooltip-icon-container {
        padding-left: 3px;

        .tooltip-icon {
            position: relative;
            top: 3px;
        }
    }
}

/*
* Section below is for bdl-namespace backwards compatibility.
* Do not add changes below this line.
*/
.label {
    @extend .bdl-Label;
}
