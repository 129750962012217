@import '../../styles/variables';

/**************************************
 * Notifications
 **************************************/

.notifications-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $notifications-wrapper-z-index;
    height: 0; /* allows elements around notifications to be clickable */
}

.notification {
    display: flex;
    align-items: center;
    max-width: 572px;
    min-height: 48px;
    margin: 10px auto;
    padding: 10px 10px 10px 20px;
    overflow: hidden;
    color: $white;
    font-weight: bold;
    background-color: $bdl-gray-80;
    border-radius: 4px;
    box-shadow: 0 2px 6px fade-out($black, .85);
    opacity: .9;
    transition: opacity .1s ease-out;

    &.info {
        background-color: $bdl-green-light;
    }

    &.warn {
        background-color: $bdl-yellorange;
    }

    &.error {
        background-color: $bdl-watermelon-red;
    }

    &.is-hidden {
        box-shadow: none; /* to improve compositing performance */
        opacity: 0;
    }

    > span {
        margin-right: auto;
        padding-right: 10px;
        font-size: 15px;
    }

    > svg {
        flex: none;
        margin-right: 15px;
    }

    &.wrap > span {
        overflow: hidden;
        word-wrap: break-word; /* Include legacy name to support older browsers */
        overflow-wrap: break-word;
    }

    &.ellipsis > span {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    button,
    a {
        flex: none;
        color: $white;

        &.btn.is-disabled,
        &.btn:not(.is-disabled) {
            margin: 0 5px;
            padding: 7px 13px;
            background-color: transparent;
            border-color: $bdl-gray-10;
        }

        &.close-btn {
            display: flex;
            padding: 2px 7px;
            font-weight: bold;
            font-size: 14px;
            background: none;
            border: none;
            outline: none;
            cursor: pointer;
            opacity: .6;
        }
    }
}
