@import '../../styles/variables';

/**************************************
 * Toggle
 **************************************/

.toggle-container {
    margin: 0 0 20px;

    .toggle-label {
        display: inline-block;
        margin: 5px 10px;
        line-height: 15px;
        vertical-align: top;
    }
}

.bdl-Toggle {
    display: none;

    &,
    &::after,
    &::before,
    *,
    *::after,
    *::before,
    & + .toggle-btn {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &::selection {
            background: none;
        }
    }

    + .toggle-btn {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
        margin: 1px 0;
        padding: 2px;
        background: $bdl-gray-30;
        border-radius: 40px;
        outline: 0;
        cursor: pointer;
        transition: left .4s ease, background .4s ease;

        &::after,
        &::before {
            position: relative;
            display: block;
            width: 26px;
            content: '';
        }

        &::after {
            top: -3px;
            left: -3px;
            height: 26px;
            background: $white;
            border: 1px solid $bdl-gray-62;
            border-radius: 50%;
            box-shadow: 0 1px 2px fade-out($black, .9);
            transition: left .2s ease, background .2s ease;
        }

        &::before {
            display: none;
        }
    }

    &:checked {
        + .toggle-btn {
            background: $primary-color;

            &::after {
                left: 50%;
            }
        }
    }
}

/**********************************************************
 * A simpler version of toggle that does not use ids
 * Eventually we should remove the webapp specific version
 **********************************************************/
$height: 20px;
$width: $height * 2;

.toggle-simple {
    position: relative;
    display: flex;
    width: $width;
    height: $height;
    border-radius: $height;
    outline: none;
    user-select: none;

    .is-toggle-right-aligned & {
        width: 100%;
    }
}

.toggle-simple-input {
    position: absolute;
    left: -9999px;
    opacity: 0; // this keeps the element tab-able
}

.toggle-simple-label {
    display: inline-block;
    min-width: 0;
    margin-left: $width + 6;
    white-space: nowrap;

    .is-toggle-right-aligned & {
        flex: 1;
        margin-left: auto;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.toggle-simple-description {
    margin-top: 2px;
    margin-left: $width + 6;
    color: $bdl-gray-50;

    .is-toggle-right-aligned & {
        margin-right: $width + 6;
        margin-left: auto;
    }
}

.toggle-simple-switch {
    display: inline-block;

    // NOTE: targets ie11
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        cursor: pointer;
    }

    .is-toggle-right-aligned & {
        position: relative;
        width: $width;
    }

    &::before,
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        cursor: pointer;
        content: '';
    }

    &::before {
        right: 0;
        background-color: $bdl-gray-30;
        border-radius: $height;
        transition: background .4s;
    }

    &::after {
        width: $height;
        height: $height;
        background-color: $white;
        border: 1px solid $bdl-gray-62;
        border-radius: 100%;
        transition: margin .4s;
    }

    .toggle-simple-input:checked ~ & {
        &::before {
            background-color: $primary-color;
        }

        &::after {
            margin-left: $height;
        }
    }

    .toggle-simple-input:disabled ~ & {
        &::before,
        &::after {
            cursor: default;
        }

        &::before {
            opacity: .5;
        }

        &::after {
            background-color: darken($white, 2%);
            border-color: lighten($bdl-gray-62, 32%);
        }
    }

    .toggle-simple-input:focus ~ & {
        &::after {
            border-color: $primary-color;
        }
    }
}

/*
* Section below is for bdl-namespace backwards compatibility.
* Do not add changes below this line.
*/
.toggle {
    @extend .bdl-Toggle;
}
