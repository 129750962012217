@import '../../styles/variables';

.bdl-EnumMetadataField {
    margin: 0 0 20px;

    .bdl-Label,
    .label {
        word-wrap: break-word;
    }

    label .select-container {
        display: block;

        .datalist-item.select-option:first-child {
            color: $bdl-gray-62;

            .select-option-check-icon {
                visibility: hidden;
            }
        }
    }

    .bdl-EnumMetadataField-desc {
        display: inline-block;
        margin-bottom: 5px;
        color: $bdl-gray-62;
        word-wrap: break-word;
    }
}
