@import '../../styles/variables';

.usm-collaborator-list {
    .collaborator-list-item {
        display: flex;
        justify-content: space-between;
        margin: 10px;

        .user {
            display: flex;
            align-items: center;
            white-space: nowrap;
        }

        .avatar {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .name {
            max-width: 200px;
            overflow: hidden;
            color: $bdl-gray;
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            text-overflow: ellipsis;
        }

        .name .link {
            color: $bdl-gray;
        }

        .email .link {
            color: $bdl-gray-62;
        }

        .name .link:hover,
        .email .link:hover {
            text-decoration: underline;
        }

        .name.group {
            line-height: 32px;
        }

        .email {
            max-width: 200px;
            overflow: hidden;
            color: $bdl-gray-62;
            font-size: 12px;
            line-height: 16px;
            text-overflow: ellipsis;
        }

        .role {
            color: $bdl-gray-62;
            line-height: 32px;
        }
    }

    .collaborator-list-item.more {
        justify-content: inherit;

        .link {
            color: $bdl-box-blue;
            line-height: 32px;
            text-decoration: none;
        }

        .collaboration-badge {
            margin-right: 8px;
        }
    }
}
