@import '../variables';

.be-inline-breadcrumbs {
    display: flex;
    min-width: 0;
    color: $bdl-gray-62;
    font-size: 11px;

    .be-breadcrumbs {
        padding-right: 0;
    }

    .be-breadcrumb {
        &:first-child {
            min-width: 43px;
        }

        .be & .btn-plain {
            color: inherit;
            font-size: 11px;
        }
    }

    .be-breadcrumb-more {
        min-width: 16px;
    }
}
