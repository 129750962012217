/**************************************
 * Overlay
 **************************************/

$opacity-duration: .075s;
$transform-duration: .125s;

.overlay-wrapper {
    position: relative;
    display: inherit;

    .overlay {
        position: absolute;
        z-index: 90;
        transform: rotateY(-10deg) rotateX(-10deg);
        transform-origin: left top;
        transform-style: preserve-3d;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear $opacity-duration, opacity $opacity-duration, transform $transform-duration;
        pointer-events: none;
    }

    &.is-visible {
        .overlay {
            transform: rotateY(0deg) rotateX(0deg);
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
            pointer-events: all;
        }
    }
}

.overlay {
    margin: 0;
    padding: 8px 0;
    background: $white;
    border: 1px solid $bdl-gray-30;
    box-shadow: 0 2px 6px 0 fade-out($black, .9);

    hr {
        height: 0;
        margin: 8px 0;
        border: 0;
        border-top: 1px solid $bdl-gray-30;
    }
}

.toggle-overlay-container {
    > .btn {
        margin-bottom: 0;
        margin-left: 0;
    }
}

.toggle-overlay {
    .overlay {
        margin-top: 5px;
    }
}
