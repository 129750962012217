@import '../../styles/variables';

$bdl-MetadataBasedItemList-insetShadowLeft: -4px 0 2px -2px $bdl-gray-10 inset;
$bdl-MetadataBasedItemList-insetShadowRight: 3px 0 3px -2px $bdl-gray-10 inset;

@mixin bdl-MetadataBasedItemList-editCellReset {
    width: 165px;
    height: 30px;
    margin: 0;
    border: 1px solid transparent;
    box-shadow: none;
}

.bdl-MetadataBasedItemList {
    // sass-lint:disable class-name-format
    .ReactVirtualized__Grid {
        outline: none;
    }

    // stylelint-disable declaration-no-important
    .ReactVirtualized__Grid__innerScrollContainer {
        overflow: visible !important;
    }
}

.bdl-MetadataBasedItemList-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $bdl-gray-62;
    border-bottom: 1px solid $bdl-gray-10;

    & .icon-pencil {
        margin-left: 10px;
    }

    .bdl-ReadOnlyMetadataField {
        margin-bottom: 0;
    }
}

.bdl-MetadataBasedItemList-cell--edit {
    display: flex;
    height: 32px;
    background-color: $white;
    border: 1px solid $bdl-gray-20;
    border-radius: 3px;

    .bdl-MetadataBasedItemList-cell--cancelIcon,
    .bdl-MetadataBasedItemList-cell--saveIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        border: 0;
        border-left: 1px solid $bdl-gray-20;
        border-radius: 0;
    }

    .text-input-container {
        input[type='text'] {
            @include bdl-MetadataBasedItemList-editCellReset;
        }
    }

    .select-container {
        .select-button {
            @include bdl-MetadataBasedItemList-editCellReset;
        }
    }

    .date-picker-icon-holder {
        .date-picker-input {
            padding-right: 40px;

            &:hover {
                box-shadow: none;
            }

            @include bdl-MetadataBasedItemList-editCellReset;
        }
    }
}

.bdl-MetadataBasedItemList-cell--filename {
    color: $bdl-gray;

    & .btn-plain {
        text-align: left;
        outline: none;
    }
}

.bdl-MetadataBasedItemList-cell--hover {
    background-color: $bdl-light-blue-10;
}

.bdl-MetadataBasedItemList-cell--fileIcon {
    border-left: 2px solid transparent;

    &.bdl-MetadataBasedItemList-cell--hover {
        border-left-color: $bdl-dark-blue;
    }

    svg {
        margin: 0 10px;
    }
}

.bdl-MetadataBasedItemList-bottomRightGrid.is-scrolledRight,
.bdl-MetadataBasedItemList-topRightGrid.is-scrolledRight {
    box-shadow: $bdl-MetadataBasedItemList-insetShadowRight;
}

.bdl-MetadataBasedItemList-bottomRightGrid.is-scrolledLeft,
.bdl-MetadataBasedItemList-topRightGrid.is-scrolledLeft {
    box-shadow: $bdl-MetadataBasedItemList-insetShadowLeft;
}

.bdl-MetadataBasedItemList-bottomRightGrid.is-scrolledMiddle,
.bdl-MetadataBasedItemList-topRightGrid.is-scrolledMiddle {
    box-shadow: $bdl-MetadataBasedItemList-insetShadowLeft, $bdl-MetadataBasedItemList-insetShadowRight;
}
