@import '../../styles/variables';

/**************************************
 * Loading Indicators
 **************************************/

.crawler {
    width: 10px;
    margin: 0 auto;
    font-size: 0;
    white-space: nowrap;
}

.crawler div {
    display: inline-block;
    width: 2px;
    height: 10px;
    background-color: $primary-color;
    border-radius: 4px;
    -webkit-animation: crawler .66s infinite ease-in-out;
    animation: crawler .66s infinite ease-in-out;
}

.btn-primary .crawler div {
    background-color: $white;
}

.crawler.is-small {
    transform: scale(.7);
}

.crawler.is-medium {
    transform: scale(1.5);
}

.crawler.is-large {
    transform: scale(2);
}

.is-loading .crawler div {
    transform: translateZ(0);
    will-change: transform, opacity;
}

.crawler div:nth-child(2) {
    margin-left: 2px;
    -webkit-animation-delay: .1s;
    animation-delay: .1s;
}

.crawler div:last-child {
    left: 8px;
    margin-left: 2px;
    -webkit-animation-delay: .2s;
    animation-delay: .2s;
}

@-webkit-keyframes crawler {
    0%,
    80%,
    100% {
        -webkit-transform: scaleY(1.2);
        opacity: .5;
    }

    40% {
        -webkit-transform: scaleY(1.6);
        opacity: 1;
    }
}

@keyframes crawler {
    0%,
    80%,
    100% {
        transform: scaleY(1.2);
        opacity: .5;
    }

    40% {
        transform: scaleY(1.6);
        opacity: 1;
    }
}
