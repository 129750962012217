@import '../common/variables';

.bcu-overall-progress-bar {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    padding-right: 65px;
    padding-left: 30px;
    color: $white;
    line-height: 60px;
    background: $blue;
    cursor: pointer;

    &:hover {
        background: lighten($blue, .8);
    }

    .bcu-progress {
        background: white;
    }

    .bcu-progress-container {
        background: transparent;
    }

    .bcu-upload-status {
        flex: none;
        margin-right: 20px;
        font-size: 16px;
    }
}
