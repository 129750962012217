@import '../variables';

.be .btn-plain.be-breadcrumbs-drop-down {
    color: $bdl-gray-62;
    letter-spacing: 1.5px;

    &.be-breadcrumbs-condensed {
        letter-spacing: inherit;
    }
}
