@import '../../styles/variables';
@import '../../styles/mixins/overlay';

// Menus are rendered at the root of the DOM, so this style is not nested
.usm-menu-description {
    display: block;
    color: $bdl-gray-62;
    font-size: 12px;
}

.usm-ftux-tooltip {
    max-width: 325px;
    padding: 20px;

    .ftux-tooltip-title {
        font-weight: bold;
    }

    .tooltip-close-button {
        top: 8px;
        right: 4px;
    }

    .ftux-tooltip-controls {
        overflow: hidden;
    }

    .ftux-tooltip-button {
        float: right;
        margin: 0;
    }

    .ftux-tooltip-link {
        color: $white;
        white-space: normal;
        text-decoration: underline;
    }

    h4 {
        margin: 0 0 10px;
    }

    p {
        margin: 0;
    }
}

.usm-share-access-menu .menu-item.is-select-item.is-selected::before,
.usm-share-permissions-menu .menu-item.is-select-item.is-selected::before {
    bottom: inherit;
}

.unified-share-modal {
    /** title and classifiction label */
    .bdl-UnifiedShareModalTitle-classification {
        display: inline;
    }

    .bdl-UnifiedShareModalTitle {
        margin-right: 6px;
    }

    /** overrides for USM sub-components */
    .text-area-container {
        position: static;
    }

    .selector-dropdown-wrapper {
        .is-visible {
            z-index: 1;
        }
    }

    .loading-indicator-veil {
        z-index: $modal-dialog-z-index + 2;
    }

    /*** Invite collaborators section ***/
    .invite-collaborator-container {
        position: relative;
    }

    .invitee-menu-wrap {
        display: flex;
        margin-top: 10px;
    }

    /*** Email form ***/
    .inline-alert {
        margin-top: 0;
        margin-bottom: 20px;
    }

    .bdl-PillSelectorDropdown .bdl-PillSelector, // need both selectors for specificity
    textarea {
        /* override for DOM element in `TextArea` which provides no id/class for targeting */
        width: 100%;
    }

    .bdl-PillSelectorDropdown {
        margin-bottom: 10px;
    }

    .bdl-PillSelectorDropdown.scrollable {
        // In the USM, the wrapper acts as a container
        .overlay-wrapper {
            @include bdl-Overlay-container;

            position: absolute;
            height: 280px;
            padding: 0;
            overflow-y: scroll;
        }

        .overlay {
            margin-top: 0;
            border: none;
            box-shadow: none;
        }
    }

    .is-expanded {
        .text-area-container {
            min-height: 89px;
            margin: 20px 0 0;
        }

        .text-area-container textarea::placeholder {
            color: $bdl-gray-50;
        }

        .bdl-PillSelector {
            min-height: 68px;
        }
    }

    .bdl-PillSelector {
        margin-top: 10px;
        border: 1px solid $bdl-gray-20;

        .bdl-PillSelector-input::placeholder {
            color: $bdl-gray-50;
        }

        &.bdl-is-disabled,
        &.is-disabled {
            color: $bdl-gray-30;
            background-color: $bdl-gray-02;
            box-shadow: none;

            .bdl-PillSelector-input::placeholder {
                color: $bdl-gray-50;
            }

            &:hover {
                border-color: $bdl-gray-30;
            }
        }
    }

    /*** Shared link section ***/
    .shared-link-toggle-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .share-toggle-container {
            flex: 0 1 80%;
            margin-bottom: 0;
            overflow-y: hidden;

            .toggle-container {
                margin-bottom: 0;
            }

            .toggle-simple-label {
                color: $bdl-gray;
                cursor: pointer;
            }
        }

        .shared-link-settings-btn-container {
            flex: 0 1 20%;
            text-align: right;

            .shared-link-settings-btn {
                margin-left: 5px;
                color: $primary-color;
                white-space: nowrap;
            }
        }
    }

    .shared-link-field-row {
        display: flex;
        margin: 10px 0;

        .shared-link-field-container {
            flex: 1;
            align-items: center;
        }

        .text-input-with-copy-button-container {
            margin-bottom: 0;
        }

        .text-input-container {
            flex: 1;

            input {
                margin-top: 0;
            }
        }

        .email-shared-link-btn {
            margin: 0 0 0 5px;
            padding: 6px 10px 6px;
        }
    }

    .shared-link-access-row {
        .btn-plain {
            margin-right: 10px;

            &.can-edit-btn {
                color: $bdl-gray-62;
            }
        }
    }

    .security-indicator-note {
        margin-top: 10px;
        color: $bdl-gray-62;

        .security-indicator-icon-globe {
            padding-right: 5px;

            .icon-globe {
                display: inline-block;
                margin-bottom: -2px;

                .fill-color {
                    fill: $bdl-gray-50;
                }
            }
        }
    }

    .upgrade-badge {
        margin-right: 5px;
    }

    .upgrade-link {
        color: $bdl-box-blue;
    }

    .upgrade-description {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .shared-link-expiration-badge {
        padding: 0 5px;

        .bdl-IconClock {
            display: inline-block;
            margin-bottom: -3px;
        }
    }

    .manage-all-btn {
        float: right;
        margin: -5px 0 10px 0;
        color: $bdl-box-blue;
    }
}

.bdl-UnifiedShareForm {
    @extend .unified-share-modal;

    max-width: $bdl-grid-unit * 115;
    height: $bdl-grid-unit * 125;
    margin: 0 auto;
    padding: 2em;
}

.bdl-SharedLinkSection-separator {
    margin: ($bdl-grid-unit * 5) 0;
    border: none;
    border-top: 1px solid $bdl-gray-20;
}
