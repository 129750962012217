.bdl-GridView {
    .ReactVirtualized__Table__rowColumn:first-child {
        margin: 0;
    }

    .bce-more-options {
        align-self: flex-start;
    }

    .bce-btn-more-options {
        margin-top: 0;
        margin-right: 0;
        padding: 5px 10px;

        .btn-content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.bdl-GridView-body {
    padding: 5px;
    outline: none;
}

.bdl-GridView-row {
    display: flex;
    justify-content: flex-start;
}

.bdl-GridView-tableRow {
    width: 100% !important;
    padding-right: 0 !important;
    border: none;
}
