@import '../variables';

.be-sub-header-right {
    display: flex;
    align-items: center;

    .be-btn-sort {
        margin-left: 7px;
    }

    .bdl-ViewModeChangeButton {
        margin-left: 7px;
    }
}
