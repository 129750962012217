@import '../../styles/variables';
@import '../../styles/mixins/overlay';

.aria-menu {
    @include bdl-Overlay-container;

    margin: 0;
    white-space: nowrap;
    list-style: none;
    background: $white;

    &:not(.should-outline-focus):focus {
        outline: none;
    }

    [role='separator'] {
        height: 0;
        margin: ($bdl-grid-unit * 2) $bdl-grid-unit;
        border: 0;
        border-top: 1px solid $bdl-gray-10;
    }

    a,
    a:hover,
    a:focus,
    .lnk,
    .lnk:hover,
    .lnk:focus {
        text-decoration: none;
    }
}

.menu-item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 30px;
    color: $bdl-gray;
    cursor: pointer;

    @include bdl-Overlay-listItemContainer;

    &.menu-section-header {
        color: $black;
        font-weight: bold;
        font-size: 10px;
    }

    &[aria-disabled] {
        cursor: default;
        opacity: .4;
    }

    &.is-select-item {
        position: relative;
        padding-left: 38px;

        /* checkmark */
        &.is-selected::before {
            position: absolute;
            top: 10px;
            left: 14px;
            width: 6px;
            height: 12px;
            margin: auto;
            border-color: $bdl-gray;
            border-style: none solid solid none;
            border-width: 2px;
            transform: rotate(45deg);
            content: '';
        }
    }

    &:not([aria-disabled]) {
        &:focus,
        &:hover {
            color: $bdl-gray;
            background-color: $bdl-gray-05;
        }
    }

    svg:not(.menu-item-arrow) {
        margin-right: $bdl-grid-unit * 4;
    }
}
