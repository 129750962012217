.bce-ItemGridCell {
    margin: 0;

    .be-inline-breadcrumbs {
        margin-bottom: 15px;
    }
}

.bce-ItemGridCell-figcaption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
}
