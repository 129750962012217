@import '../../styles/variables';

/**************************************
 * Pill Selector
 **************************************/

.bdl-PillSelector-input--hidden {
    position: absolute;
    visibility: hidden;
}

.bdl-PillSelectorDropdown {
    position: relative;
    margin: 0 0 20px;

    .bdl-PillSelector {
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        align-items: flex-start;
        width: 262px;
        margin-top: 5px;
        padding: 5px;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: $white;
        border: 1px solid darken($white, 20%);
        border-radius: $bdl-border-radius-size;
        box-shadow: inset 0 1px 1px fade-out($black, .92);
        cursor: text;
        transition: border-color linear .15s, box-shadow linear .15s;
        -webkit-font-smoothing: antialiased;

        &:hover {
            border: 1px solid darken($primary-color, 10%);
        }

        &.bdl-is-disabled,
        &.is-disabled {
            color: $bdl-gray-30;
            background-color: $bdl-gray-02;
            box-shadow: none;

            &:hover {
                border-color: $bdl-gray-30;
            }
        }

        &.is-focused {
            border-color: $primary-color;
            outline: 0;
            box-shadow: none;
        }

        &.show-error {
            border-color: $bdl-watermelon-red;
        }

        &.bdl-PillSelector--suggestionsEnabled {
            min-height: $bdl-line-height * 4;
        }

        &:not(.show-error) {
            .icon-alert {
                display: none;
            }
        }

        .icon-alert {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 16px;
            height: 16px;

            path {
                fill: $bdl-watermelon-red;
            }
        }

        .bdl-Tooltip,
        .tooltip {
            top: calc(100% - 5px) !important;
            right: 10px !important;
            margin-top: 0 !important;
        }

        .bdl-PillsList {
            margin: 0;

            li {
                float: left;
            }
        }

        .bdl-PillSelector-input {
            flex: 1;
            box-sizing: content-box !important;
            min-width: 0;
            height: 18px;
            margin: 1px;
            padding: 3px;
            overflow: hidden;
            color: lighten($black, 31%);
            line-height: 18px;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            resize: none;

            &:focus,
            &:hover {
                outline: none;
            }
        }

        // when using the larger pills with user avatars,
        // increase textarea line height to align text with pill content
        .bdl-RoundPill ~ .bdl-PillSelector-input {
            height: $bdl-line-height + $bdl-grid-unit * 2;
            line-height: $bdl-line-height + $bdl-grid-unit * 2;
        }
    }
}

/*
* Section below is for bdl-namespace backwards compatibility.
* Do not add changes below this line.
*/
.pill-selector-suggestions-enabled {
    @extend .bdl-PillSelector--suggestionsEnabled;
}

.pill-selector-hidden-input {
    @extend .bdl-PillSelector-input--hidden;
}

.pill-selector-wrapper {
    @extend .bdl-PillSelectorDropdown;
}

.pill-selector-input {
    @extend .bdl-PillSelector-input;
}

.pill-selector-input-wrapper {
    @extend .bdl-PillSelector;
}

.pills-list {
    @extend .bdl-PillsList;
}
