@import '../../styles/variables';

.shared-link-settings-modal {
    hr {
        /* We have to get rid of top margin because fieldsets do not support margin collapsing */
        margin-top: 0;
    }

    .date-picker-wrapper {
        margin-bottom: 0;
    }

    .inline-alert {
        margin: 0 0 20px;
    }

    .checkbox-subsection {
        max-width: 400px;
        margin-left: 0;

        .custom-url-preview {
            overflow: hidden;
        }
    }

    .vanity-name-section {
        .text-input-container {
            margin-bottom: 10px;
        }

        .quarantine-badge {
            margin-right: 4px;
            transform: translateY(3px);
        }
    }

    .text-input-container input[type='text'],
    .date-picker-icon-holder,
    .date-picker-icon-holder input[type='text'],
    .text-input-container input[type='password'] {
        width: 100%;
    }

    .direct-link-input .text-input-container {
        flex: 1;
    }

    /* Since margin collapsing doesn't work for fieldsets, adjust modal-actions top margin */
    .modal-actions {
        margin-top: 10px;
    }

    .link-settings-modal-notice {
        margin: 0 0 ($bdl-grid-unit * 5);
        color: $bdl-gray-62;
    }
}

/** title and classifiction label */
.bdl-SharedLinkSettingsModal-title {
    display: flex;
    flex-flow: row wrap;
}

.bdl-SharedLinkSettingsModal-classification {
    margin-left: 6px;
}
