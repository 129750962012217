@import '../../styles/variables';

.bdl-SelectButton {
    &.is-invalid {
        border-color: $bdl-watermelon-red;
    }
}

/*
* Section below is for bdl-namespace backwards compatibility.
* Do not add changes below this line.
*/
.select-button {
    @extend .bdl-SelectButton;
}
