@import '../common/variables';

.be.bcu-uploads-manager-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 0;
    transition: max-height .5s;

    .bcu-progress-container {
        margin-right: 0;
    }

    .bcu-item-progress {
        .bcu-progress-container {
            display: none;
        }

        .bcu-progress-label {
            margin-left: auto;
        }
    }

    &.bcu-is-visible {
        max-height: 60px;
    }

    .bcu-uploads-manager-item-list {
        display: none;
        height: 375px;

        .buik-btn-plain:focus {
            border: 1px solid $bdl-gray-30;
        }
    }

    .bcu-uploads-manager-toggle {
        position: absolute;
        top: 0;
        right: 26px;
        bottom: 0;
        width: 10px;
        height: 10px;
        margin: auto 0;
        vertical-align: middle;
        background: 0 0;
        border: 0;

        &::before,
        &::after {
            position: absolute;
            width: 6px;
            height: 6px;
            border: 0 solid $white;
            content: '';
        }

        &::before {
            bottom: 0;
            left: 0;
            border-bottom-width: 2px;
            border-left-width: 2px;
        }

        &::after {
            top: 0;
            right: 0;
            border-top-width: 2px;
            border-right-width: 2px;
        }
    }

    &.bcu-is-expanded {
        max-height: 435px;

        .bcu-uploads-manager-item-list {
            display: block;
        }

        .bcu-uploads-manager-toggle {
            &::before {
                bottom: 7px;
                left: 7px;
            }

            &::after {
                top: 7px;
                right: 7px;
            }
        }
    }

    .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle {
        &::before {
            bottom: -3px;
            left: -3px;
        }

        &::after {
            top: -3px;
            right: -3px;
        }
    }

    &.bcu-is-expanded .bcu-overall-progress-bar:hover .bcu-uploads-manager-toggle {
        &::before {
            bottom: 6px;
            left: 6px;
        }

        &::after {
            top: 6px;
            right: 6px;
        }
    }

    .bcu-item-row {
        padding-right: 5px;
    }

    .bcu-item-icon-name {
        padding-left: 5px;
    }

    .bcu-progress {
        box-shadow: none;
    }

    .bcu-item-list {
        position: relative;
    }
}
