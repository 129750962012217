@import '../../styles/variables';

.text-input-with-copy-button-container {
    display: flex;
    align-items: flex-end;
    margin: 0 0 20px;

    .text-input-container {
        margin-right: 0;
        margin-bottom: 0;

        input,
        input:focus {
            width: 100%;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .bdl-Button,
    .btn {
        min-width: 80px;
        margin: 0;
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transition: background-color .5s ease, border-color .5s ease;
    }

    &.copy-success {
        .btn,
        .btn:hover,
        .btn:active,
        .btn:active:hover {
            color: $white;
            background-color: $bdl-green-light;
            border-color: $bdl-green-light;
        }

        input:focus {
            border-color: $bdl-green-light;
        }
    }
}
