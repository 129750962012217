@import '../../styles/variables';
@import '../badgeable/mixins';

/**************************************
 * Avatars
 **************************************/

.avatar {
    position: relative;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    user-select: none;

    %circle {
        @include bdl-transition($bdl-transitionDurationBase * 2, transform);

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        backface-visibility: hidden;
    }

    .avatar-icon,
    .avatar-image {
        @extend %circle;
    }

    .avatar-initials {
        @extend %circle;

        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-weight: bold;
        line-height: 1;
    }

    &.avatar--isExternal {
        .bdl-Avatar-externalBadge {
            width: 14px;
            height: 14px;
            border-width: 1px;
        }

        @include Badgeable-bottomRightBadge {
            bottom: -4px;
            left: calc(100% - 10px);
        }
    }

    @for $i from 1 through length($avatar-colors) {
        .avatar-initials[data-bg-idx='#{$i - 1}'] {
            background-color: nth($avatar-colors, $i);
        }
    }

    &.avatar--small {
        width: 24px;
        height: 24px;

        .avatar-initials {
            font-size: 11px;
        }

        &.avatar--isExternal {
            .bdl-Avatar-externalBadge {
                width: 16px;
                height: 16px;
                border-width: 2px;
            }

            @include Badgeable-bottomRightBadge {
                bottom: -4px;
                left: calc(100% - 10px);
            }
        }
    }

    &.avatar--large {
        width: 44px;
        height: 44px;

        .avatar-initials {
            font-size: 14px;
        }
    }
}

.bdl-Avatar-externalBadge {
    background-color: $white;
    border-color: $white;
    border-style: solid;
    border-radius: 50%;
}
