@import '../../styles/variables';

.bdl-LabelPill {
    color: $bdl-gray;
    font-weight: bold;
    border: 1px solid;
}

.bdl-LabelPill--sizeRegular {
    padding: 3px;
    font-size: 10px;
    line-height: 10px;
    border-radius: 10px;
}

.bdl-LabelPill--sizeLarge {
    padding: $bdl-grid-unit;
    font-size: $bdl-fontSize;
    line-height: $bdl-line-height;
    border-radius: $bdl-line-height;
}

.bdl-LabelPill--default {
    background-color: $bdl-gray-10;
    border-color: $bdl-gray-20;
}

.bdl-LabelPill--warning {
    background-color: $bdl-yellorange-10;
    border-color: $bdl-yellorange;
}

.bdl-LabelPill--error {
    background-color: $bdl-watermelon-red-10;
    border-color: $bdl-watermelon-red;
}

.bdl-LabelPill--success {
    background-color: $bdl-green-light-10;
    border-color: $bdl-green-light;
}

.bdl-LabelPill--alert {
    background-color: $bdl-watermelon-red-10;
    border-color: $bdl-watermelon-red;
}

.bdl-LabelPill--highlight {
    background-color: $bdl-light-blue-10;
    border-color: $bdl-light-blue;
}

.bdl-LabelPill--info {
    background-color: $bdl-light-blue-10;
    border-color: $bdl-light-blue;
}

.bdl-LabelPill-iconContent {
    position: relative;
    top: 2px;
}

.bdl-LabelPill--sizeLarge {
    .bdl-LabelPill-textContent {
        margin: 0 $bdl-grid-unit;
    }

    .bdl-LabelPill-iconContent {
        top: 1px;
    }
}

.bdl-LabelPill-textContent {
    margin: 0 3px;
}
