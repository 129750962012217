@import '../common/variables';

.bcp-content {
    position: relative;
    display: flex;
    flex: 1 0 160px;
    flex-direction: column;
}

.bcp-share-access-loading {
    display: flex;
    align-items: center;
    height: 30px;
}
