@import '../../elements/common/variables';
@import '../../styles/variables';

.bce-ItemGridThumbnail {
    position: relative;
    margin-bottom: 2px;
    // padding is used to maintain cell's aspect ratio on resize of container
    padding-bottom: 65%;
    background-color: $bdl-gray-02;
}

.bce-ItemGridThumbnail-item {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    svg {
        width: 60%;
        height: 60%;
    }
}
