@import '../common/variables';
@import './mixins';

.bcs .bcs-NavButton {
    @include bdl-SidebarNavButton;

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        display: block;
        width: 3px;
        content: '';
        pointer-events: none;
    }

    &.bcs-is-selected {
        &::before {
            background-color: $blue;
        }

        svg .fill-color {
            fill: $blue;
        }
    }

    &:hover {
        background-color: $bdl-gray-05;

        &:not(.bcs-is-selected) svg .fill-color {
            fill: $bdl-gray-80;
        }
    }
}
