@import '../common/variables';

.bcu-item-icon-name {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: default;
}

.bcu-item-icon {
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    justify-content: center;
}

.bcu-item-name {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    line-height: 15px;
    text-align: left;
}

.bcu-icon-badge {
    .badges {
        .bottom-right-badge {
            bottom: -4px;
            left: calc(100% - 16px);
        }
    }
}
