@import '../common/variables';

$sidebarTabsWidth: 60px;
$sidebarWidth: $sidebarTabsWidth + $sidebarContentWidth;

.be {
    &.bcs {
        display: flex;
        width: auto;
        min-width: $sidebarTabsWidth;
        max-width: $sidebarWidth;
        background-color: $bdl-gray-02;
    }

    .bcs-loading {
        display: flex;
        flex: 1;
        align-items: center;
        width: 10px;
        margin: 0 auto;
        font-size: 0;
        white-space: nowrap;
    }

    &.bcs-is-open {
        .bcs-loading {
            width: $sidebarWidth;
        }
    }
}
