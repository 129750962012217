@import '../../styles/variables';

.pill-selector-suggested {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    color: $bdl-gray-50;

    > span {
        margin-left: 3px;
    }

    .suggested-pill-invisible-button {
        padding: 0;
    }

    .suggested-pill {
        margin: 0 3px;
        padding: 0;
        color: $bdl-gray-50;
        text-decoration: underline;
        transition: 100ms ease;
    }

    .suggested-pill:hover {
        cursor: pointer;
    }

    .suggested-pill span {
        padding: 0 3px;
    }

    .invisible-button:hover .suggested-pill {
        color: $bdl-gray-80;
    }
}
