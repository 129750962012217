.be-item-icon {
    display: flex;
    flex: 0 0 50px;
    align-items: center;
    justify-content: center;

    .be-is-small & {
        flex: 0 0 30px;
    }
}
