@import '../common/variables';

.bcp-footer {
    display: flex;
    flex: 0 1 70px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background-color: $almost-white;
    border-top: 1px solid $bdl-gray-10;

    .bcp-footer-left {
        flex: 0 1 100%;
        min-width: 0;
    }

    .bcp-selected {
        max-width: 100%;
        margin: 0;

        .btn-content {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            line-height: 13px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .bcp-selected-max {
        display: inline-block;
        margin-left: 5px;
        color: $red;
        font-size: 11px;
        animation: be_pulse .5s 2 linear;
    }

    .bcp-footer-actions {
        .btn {
            width: 40px;
            height: 32px;
            padding: 0;
        }

        .btn:first-child {
            border-right: 0;
        }

        .btn-primary svg {
            fill: #fff;
        }
    }

    .bcp-footer-right {
        display: flex;
        flex: 1 0 auto;
    }

    .bdl-ButtonGroup,
    .btn-group {
        margin-right: 5px;
        margin-left: 5px;
    }
}

@keyframes be_pulse {
    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }
}
