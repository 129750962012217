@import '../../styles/variables';

.collaborator-avatar-container {
    position: relative;
    z-index: $modal-dialog-z-index + 1;
    display: flex;
    align-items: center;
    float: right;

    &.are-avatars-hidden {
        display: none;
    }

    .avatars-label {
        margin-right: 5px;
        color: $bdl-gray-62;
    }

    .avatars {
        line-height: 0;
    }

    .avatar {
        width: 24px;
        height: 24px;
        margin-left: 2px;
        font-size: 11px;
    }

    .avatars-count {
        min-width: 24px;
        max-width: 30px;
        height: 24px;
        margin-left: 2px;
        color: $bdl-gray;
        font-size: 11px;
        line-height: 24px;
        text-align: center;
        vertical-align: top;
        background-color: $bdl-gray-20;
        border-radius: 14px;
    }

    .badgeable-container {
        height: 24px;
    }
}
