@import '../../styles/variables';

.bdl-GridViewSlider {
    display: flex;
    margin-right: 5px;
}

// mixins required since vendor prefixes cannot be mixed in a selector
@mixin bdl-GridViewSlider-track {
    height: 2px;
    background: $bdl-gray-10;
    border: none;
    border-radius: 2px;
}

@mixin bdl-GridViewSlider-thumb {
    width: 10px;
    height: 10px;
    background: $bdl-gray-80;
    border: none;
    border-radius: 50%;
}

.bdl-GridViewSlider-range {
    margin-right: 7px;
    margin-left: 7px;

    /* fix for FF unable to apply focus style bug */
    cursor: pointer;
    appearance: none;

    &::-webkit-slider-runnable-track {
        @include bdl-GridViewSlider-track;
    }

    &::-webkit-slider-thumb {
        @include bdl-GridViewSlider-thumb;

        width: 10px;
        margin-top: -4px;
        appearance: none;
    }

    &::-moz-range-track {
        @include bdl-GridViewSlider-track;
    }

    &::-moz-range-thumb {
        @include bdl-GridViewSlider-thumb;
    }

    &::-ms-track {
        @include bdl-GridViewSlider-track;

        color: transparent;
    }

    &::-ms-fill-lower {
        background: $bdl-gray-10;
        border-radius: 10px;
    }

    &::-ms-fill-upper {
        background: $bdl-gray-10;
        border-radius: 10px;
    }

    &::-ms-thumb {
        @include bdl-GridViewSlider-thumb;
    }

    /* hide the outline behind the border */
    &:-moz-focusring {
        outline: 1px solid $white;
        outline-offset: -1px;
    }

    &:focus {
        outline: none;
    }

    &:focus::-ms-fill-lower {
        background: $bdl-gray-50;
    }

    &:focus::-ms-fill-upper {
        background: $bdl-gray-20;
    }
}

.bdl-GridViewSlider-button {
    display: flex;
    align-items: center;
    justify-content: center;
}
