@import '../common/variables';

.bcu-drag-drop-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    opacity: .2;
}

.bcu-upload-state {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
        margin-bottom: 10px;
    }

    // Show blue indicator when item can be dropped
    &.bcu-is-droppable .bcu-drag-drop-overlay {
        display: block;
        background: $blue;
    }

    // Show red indicator when item cannot be dropped
    &.bcu-is-not-droppable .bcu-drag-drop-overlay {
        display: block;
        background: $red;
    }

    // Hide upload prompt if there are items queued
    &.bcu-has-items {
        display: none;

        // If a file is dragged over, show the prompt on top of items
        &.bcu-is-droppable,
        &.bcu-is-not-droppable {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            background-color: rgba(255, 255, 255, .8);

            .bcu-drag-drop-overlay {
                display: none;
            }
        }
    }
}

.bcu-upload-state-message + .be-input-btn {
    margin-top: 10px;
}

.bcu-upload-state-message + .be-input-link {
    margin-top: 5px;
}

.bcu-upload-input-container {
    .be-input-link {
        display: inline-block;
    }
}
