@import '../../elements/common/variables';
@import '../../styles/variables';

.bdl-GridViewSlot {
    flex: 0 1 auto;
    padding: 5px;
    overflow: hidden;
    white-space: normal;
}

.bdl-GridViewSlot-content {
    height: 100%;
    border: 1px solid $bdl-gray-10;
    box-shadow: 0 1px 5px rgba(50, 50, 50, .1);

    &:hover,
    &:active,
    &:focus {
        border-color: $bdl-box-blue-50;
    }

    &.bdl-GridViewSlot-content--selected {
        border-color: $bdl-box-blue-50;
    }
}
