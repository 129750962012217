@import '../../styles/variables';

.bdl-GuideTooltip {
    display: flex;
}

.bdl-GuideTooltip.bdl-Tooltip {
    max-width: 408px;
    padding: ($bdl-grid-unit * 6) ($bdl-grid-unit * 12) ($bdl-grid-unit * 6) ($bdl-grid-unit * 6);
}

.bdl-GuideTooltip-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ($bdl-grid-unit * 6);
}

.bdl-GuideTooltip-icon {
    width: 32px;
    margin-right: ($bdl-grid-unit * 4);
}

.bdl-GuideTooltip-title {
    padding-bottom: ($bdl-grid-unit * 2);
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
}

.bdl-GuideTooltip-body {
    font-size: 14px;
    line-height: 1.57;
}

.bdl-GuideTooltip-title,
.bdl-GuideTooltip-body {
    letter-spacing: .3px;
}

button.bdl-GuideTooltip-previousButton,
button.bdl-GuideTooltip-previousButton:not(.is-disabled):hover,
button.bdl-GuideTooltip-previousButton:not(.is-disabled):active {
    color: $bdl-dark-blue-02;
    background-color: $bdl-dark-blue;
}

button.bdl-GuideTooltip-previousButton {
    border: 1px solid $bdl-dark-blue-02;
}

button.bdl-GuideTooltip-previousButton:not(.is-disabled):hover {
    border: 1px solid $bdl-dark-blue-20;
}

button.bdl-GuideTooltip-previousButton:not(.is-disabled):active {
    border: 1px solid $bdl-dark-blue-50;
}

button.bdl-GuideTooltip-nextButton {
    color: $bdl-dark-blue;
    background-color: $bdl-dark-blue-02;
    border: 1px solid $bdl-dark-blue-02;
}

button.bdl-GuideTooltip-nextButton:not(.is-disabled):hover {
    background-color: $bdl-dark-blue-10;
    border: 1px solid $bdl-dark-blue-10;
}

button.bdl-GuideTooltip-nextButton:not(.is-disabled):active {
    background-color: $bdl-dark-blue-20;
    border: 1px solid $bdl-dark-blue-20;
}

.bdl-GuideTooltip-navigation button:first-of-type {
    margin-left: 0;
}
