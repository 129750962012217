@import '../../../styles/variables';
@import '../../../styles/common/animations.scss';
@import '../mixins';

$bdl-AdditionalTabs-fade-in-time: .5s;

.bdl-AdditionalTabPlaceholder {
    @include bdl-SidebarNavButton;
}

.bdl-AdditionalTabPlaceholder-icon {
    @include bdl-fadeIn($bdl-AdditionalTabs-fade-in-time);

    width: 24px;
    height: 24px;
    background-color: $bdl-gray-50;
    border-radius: 5px;
}

.bdl-AdditionalTabPlaceholder-icon--loading {
    @include bdl-loadingPulse(#f4f4f4, $bdl-gray-10, $bdl-AdditionalTabs-fade-in-time);
}

.bdl-AdditionalTabPlaceholder-moreIcon {
    @include bdl-fadeIn($bdl-AdditionalTabs-fade-in-time);
}
