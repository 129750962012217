@import '../common/variables';

.bcpr-PreviewLoading {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $bdl-gray;
    text-align: center;
}

.bcpr-PreviewLoading-message {
    max-width: 400px;
    margin-top: 20px;
    font-size: 14px;
}
