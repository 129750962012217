.bdl-GridView {
    .bdl-ItemSubDetails-modifiedBy,
    .bdl-ItemSubDetails-size {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.bce-item-row {
    .bdl-ItemSubDetails-size {
        &::before {
            padding-right: 3px;
            padding-left: 3px;
            font-size: 8px;
            vertical-align: middle;
            content: '•';
        }
    }
}
