@import '../variables';

.be-breadcrumbs {
    display: flex;
    align-items: center;
    min-width: 0;
    padding-right: 20px;
}

.be-breadcrumb-more {
    display: flex;
    align-items: center;
    min-width: 35px;
}
