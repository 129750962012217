@import '../variables';

@function sas-background-image-function($color) {
    @return linear-gradient(45deg, transparent 50%, $color 50%), linear-gradient(135deg, $color 50%, transparent 50%);
}

.be {
    select.be-share-access-select {
        padding: 5px 25px 5px 10px;
        color: inherit;
        background: none;
        background-image: sas-background-image-function($bdl-gray-62);
        background-repeat: no-repeat;
        background-position: right 14px center, right 10px center;
        background-size: 4px 4px, 4px 4px;
        border: 1px solid $grey;
        border-radius: 2px;
        cursor: pointer;
        appearance: none;

        &:hover {
            border-color: $blue;
        }

        &:focus,
        &:active {
            color: $blue;
            background-image: sas-background-image-function($blue);
            border-color: $blue;
            outline: none;
        }

        &:disabled {
            color: $bdl-gray-10;
            background-image: sas-background-image-function($bdl-gray-10);
            border-color: $bdl-gray-10;
        }

        /* stylelint-disable */
        &:-moz-focusring,
        &::-moz-focus-inner {
            color: transparent !important;
            text-shadow: 0 0 0 $bdl-gray !important;
        }

        &::-ms-expand {
            display: none !important;
        }
        /* stylelint-enable */
    }
}
