@import '../../styles/variables';

.usm-collaborator-list {
    .collaborator-list {
        min-width: 400px;
        height: 210px;
        margin: 0;
        overflow-y: scroll;
        border: solid 1px $bdl-gray-20;
        border-radius: 4px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .08) inset;
    }
}
