@import '../variables';

.be-progress-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $overlay-z-index;
    width: 100%;
    transition: .4s opacity;

    .be-progress {
        max-width: 100%;
        height: 2px;
        background: $blue;
        box-shadow: 0 1px 5px 0 $light-blue;
        transition: .4s width;
    }
}
