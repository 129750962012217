@import '../../styles/variables';

@keyframes dropdown_menu {
    0% {
        opacity: 0;
    }

    60% {
        opacity: 1;
    }

    100% {
        transform: rotateY(0) rotateX(0);
    }
}

@keyframes dropdown_menu_right_aligned {
    0% {
        transform: rotateY(-10deg) rotateX(-10deg);
    }
}

@keyframes dropdown_menu_left_aligned {
    0% {
        transform: rotateY(10deg) rotateX(-10deg);
    }
}

.dropdown-menu-element-attached-right .aria-menu {
    transform-origin: right top;
    animation: dropdown_menu .125s, dropdown_menu_right_aligned .125s;
}

.dropdown-menu-element-attached-left .aria-menu {
    transform-origin: left top;
    animation: dropdown_menu .125s, dropdown_menu_left_aligned .125s;
}

// Drop down menu is a tethered element and as such attached to body
// however the body may not have box specific classes and hence the
// menu needs to manually inherit few of the body styles to look correct.
.dropdown-menu-element {
    @include box-sizing;
    @include bdl-commonTypography-dejaBlue;

    z-index: $menu-z-index;
    margin-top: 5px;
    perspective: 500px;
    perspective-origin: center top;
}
