@import '../../styles/variables';
@import './variables';

.be-modal {
    .be-modal-btns {
        display: flex;
        justify-content: center;
        padding: 15px 0 0;

        .btn {
            margin-left: 8px;
        }
    }

    .be-modal-error {
        color: $red;
    }
}

.be-modal-wrapper-content,
.be-modal-dialog-content-full-bleed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background-color: $white;
    border: 0 none;
    border-radius: 0;
    outline: none;
}

.be-modal-wrapper-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $darker-black;
}

.be-modal-dialog-content {
    @extend .be-modal-wrapper-content;

    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 400px;
    margin-right: -50%;
    padding: 25px;
    border-radius: 4px;
    transform: translate(-50%, -50%);

    .be-is-small & {
        width: 340px;
    }
}

.be-modal-dialog-overlay {
    @extend .be-modal-wrapper-overlay;

    position: absolute;
}
