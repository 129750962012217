@import '../../styles/variables';

.menu-toggle {
    display: flex;
    align-items: center;

    > .toggle-arrow {
        @include bdl-transition($bdl-transitionDurationBase, transform);

        flex: none;
        margin-left: 5px;

        .dropdown-menu-enabled &,
        .is-toggled & {
            transform: rotate(180deg);
        }

        .lnk & {
            margin-top: 2px;

            & path {
                fill: $bdl-box-blue;
            }
        }
    }

    .btn-primary & .fill-color {
        fill: $white;
    }

    .lnk.bdl-is-disabled & .fill-color,
    .lnk.is-disabled & .fill-color {
        fill: $bdl-gray-50;
    }
}
