@import '../../styles/variables';

/**************************************
 * Modal
 **************************************/

@keyframes popup_bounce_in {
    0% {
        transform: scale3d(.1, .1, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

// Container modal div
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $modal-dialog-z-index;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 30px;
    overflow: auto;
    outline: 0;

    .modal-dialog {
        border: none;
        box-shadow: none;
        animation: popup_bounce_in .3s;
    }

    .modal-header {
        margin-right: 20px;
    }

    hr {
        margin: 20px 0;
        border: none;
        border-top: 1px solid $bdl-gray-20;
    }
}

.modal-dialog-container {
    margin: auto;
}

.modal-dialog {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 460px;
    margin: auto;
    padding: 30px;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $bdl-gray-30;
    border-radius: $bdl-border-radius-size;
    box-shadow: 0 1px 1px 1px fade-out($black, .95);
}

.modal-title {
    display: inline-block;
    flex: 1;
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    font-size: 16px;
    line-height: inherit;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.modal-content {
    margin-top: 20px;
}

.modal-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 2px;
    color: $bdl-gray-50;
    font-weight: bold;
    font-size: 11px;
    background: none;
    border: 0;
    cursor: pointer;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1; // .modal creates a new stacking context. Let's just make sure that backdrop is below dialog.
    background: fade-out($black, .25);
}

.modal-actions {
    display: flex;
    flex: none;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    text-align: right;

    .btn:last-of-type {
        margin-right: 0;
    }

    .btn {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 89;
    display: flex;
}
