@import '../../styles/variables';

.bdl-MultiSelectMetadataField {
    margin: 0 0 20px;

    .label {
        word-wrap: break-word;
    }

    label .select-container {
        display: block;

        .select-button {
            z-index: inherit;
        }
    }
}

.bdl-MultiSelectMetadataField-desc {
    display: inline-block;
    margin-bottom: 5px;
    color: $bdl-gray-62;
    word-wrap: break-word;
}
