@import '../../styles/variables';

.loading-indicator-wrapper {
    position: relative;

    .loading-indicator-veil {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;

        &.is-with-center-crawler {
            align-items: center;
        }

        &.blur-content {
            background-color: rgba($bdl-gray-02, .75);
        }

        &.hide-content {
            background-color: $white;
        }
    }
}
