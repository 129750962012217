@import '../../styles/variables';

/**************************************
 * Selector Dropdown
 **************************************/

.SelectorDropdown {
    position: relative;
    z-index: 3; /* increase z-index based on max-height of dropdown wrapper */

    .overlay-wrapper,
    .overlay {
        display: block;
        width: 100%;

        .SelectorDropdown-divider {
            display: block;
            width: calc(100% - 30px);
            height: 1px;
            margin: $bdl-grid-unit ($bdl-grid-unit * 4);
            border: 0;
            border-bottom: 1px solid $bdl-gray-10;
            pointer-events: none;
        }

        .SelectorDropdown-title {
            margin: ($bdl-grid-unit * 4);
            margin-bottom: $bdl-grid-unit * 2;
            color: $bdl-gray-62;
            font-weight: bold;
            font-size: 11px;
            line-height: 1;
            letter-spacing: .6px;
            text-transform: uppercase;
            pointer-events: none;
        }
    }

    .text-input-container {
        margin-bottom: 5px;
    }

    .link-group {
        ul {
            list-style: none;
        }
    }

    .link {
        color: $bdl-gray-80 !important;

        &.is-selected {
            background-color: fade-out($bdl-gray, .95);
        }
    }
}
