@import '../../styles/variables';

.bdl-DateMetadataField {
    .date-picker-icon-holder {
        width: 100%;

        .date-picker-input {
            width: 100%;
        }
    }
}
