// Constraints for the shadow shape
$scroll-shadow-height: 30px;

.scroll-container {
    position: relative;
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    .scroll-wrap-container {
        flex-grow: 1;
        overflow-y: auto;

        &::before,
        &::after {
            position: absolute;
            display: block;
            width: 100%;
            height: $scroll-shadow-height;
            border-radius: inherit;
            opacity: 0;
            transition: opacity .1s ease-in-out;
            content: '';
        }

        // default
        &::before {
            top: -$scroll-shadow-height;
            z-index: 20;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .1);
        }

        &::after {
            bottom: -$scroll-shadow-height;
            z-index: 30;
            box-shadow: 0 -2px 8px 0 rgba(0, 0, 0, .1);
        }
    }

    &.is-showing-top-shadow {
        .scroll-wrap-container::before {
            opacity: 1;
        }
    }

    &.is-showing-bottom-shadow {
        .scroll-wrap-container::after {
            opacity: 1;
        }
    }
}
