@import '../common/variables';

.bcs {
    .bcs-SidebarNav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: 1px solid $bdl-gray-10;
    }

    .bcs-SidebarNav-tabs {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
    }

    .bcs-SidebarNav-main {
        flex: 0 1 auto;
    }

    .bcs-SidebarNav-overflow {
        display: flex;
        flex: 1 1 1px; // IE11 doesn't support flex-basis of 0
        flex-direction: column;
        overflow: hidden;

        &::after,
        &::before {
            display: block;
            flex: 0 0 1px;
            width: 44px;
            height: 1px;
            margin: 0 auto;
            background: $bdl-gray-10;
            content: '';
        }

        .bdl-AdditionalTab {
            flex-shrink: 0;
        }

        .bdl-AdditionalTabs {
            flex: 1 1 100%;
            padding-top: 16px;
            padding-bottom: 16px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    .bcs-SidebarNav-footer {
        display: flex;
        flex: 0 0 60px;
        align-items: center;
        justify-content: center;

        // Need to add these overriding styles because there is a specificity issue with .btn-plain
        .btn-plain.bdl-SidebarToggleButton {
            height: 24px;
            margin: 0 3px;
            padding: 4px;
        }
    }
}
