@import '../../styles/variables';

/**************************************
 * Text Area
 **************************************/

.text-area-container {
    position: relative;
    margin: 0 0 20px;

    label {
        display: block;
    }

    .text-area-description {
        color: $bdl-gray-62;
        word-wrap: break-word;
    }

    textarea {
        margin-top: 5px;
        font-family: inherit;
    }

    &:not(.show-error) {
        .caution {
            display: none;
        }
    }

    &.show-error {
        textarea {
            border-color: $bdl-watermelon-red;
        }
    }
}
