@import '../../../styles/variables';

.bdl-SecurityControlsModal {
    .bdl-Label,
    .label {
        margin-top: 19px;
    }
}

.bdl-SecurityControlsModal-controlsItemList,
.bdl-SecurityControlsModal-definition {
    overflow-y: auto;
    line-height: $bdl-line-height;
}

.bdl-SecurityControlsModal-definition {
    max-height: $bdl-line-height * 3;
}

.bdl-SecurityControlsModal-controlsItemList {
    max-height: $bdl-line-height * 12;
    margin: 0;
    list-style: none;
}
