/**************************************
 * Buttons
 **************************************/

.btn {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    margin: 5px;
    padding: 10px 16px;
    color: $bdl-gray-80;
    font-weight: normal;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: .035em;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    vertical-align: top;
    background-color: $white;
    border: 1px solid $bdl-gray-30;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    cursor: pointer;
    -webkit-transition: border-color linear .15s, box-shadow linear .15s, background-color linear .15s;
    transition: background-color .05s ease-in-out, border-color .05s ease-in-out;
    -webkit-appearance: none;
    zoom: 1;
    -webkit-font-smoothing: antialiased;

    &:active,
    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
    }

    &:not(.bdl-is-disabled),
    &:not(.is-disabled) {
        &:hover {
            background-color: darken($white, 3%);
        }

        &:active {
            background-color: darken($white, 8%);
            border-color: $bdl-gray-30;
        }

        &:focus {
            border-color: darken($bdl-gray-30, 14%);
            box-shadow: 0 1px 2px fade-out($black, .9);
        }
    }

    &.bdl-is-disabled,
    &.is-disabled,
    &.is-loading {
        top: 0; /* prevents disabled button from being depressed on click */
        cursor: default;
    }

    &.is-loading {
        .btn-content {
            visibility: hidden;
        }

        .btn-loading-indicator {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }

    &.bdl-is-disabled,
    &.is-disabled {
        box-shadow: none;
        opacity: .4;
    }
}

.btn-plain,
.btn-plain:hover,
.btn-plain:active {
    margin: 0;
    padding: 0;
    font-weight: normal;
    border: none;
    cursor: pointer;
}

.btn-plain,
.btn-plain:hover,
.btn-plain:active,
.btn-plain:focus {
    background: transparent;
    box-shadow: none;
}

/* This setting is being used to override the dotted border on a firefox button */
button.btn-plain::-moz-focus-inner {
    border: 0 none;
}

button svg {
    pointer-events: none;
}

.btn-primary {
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
    -webkit-font-smoothing: antialiased;

    &:not(.bdl-is-disabled),
    &:not(.is-disabled) {
        &:focus {
            border-color: $primary-color;
            box-shadow: inset 0 0 0 1px fade-out($white, .2), 0 1px 2px fade-out($black, .9);
        }

        &:hover {
            background-color: lighten($primary-color, 8%);
            border-color: lighten($primary-color, 8%);
        }

        &:active {
            background-color: darken($primary-color, 8%);
            border-color: darken($primary-color, 8%);
            box-shadow: none;
        }
    }

    &.is-loading {
        .btn-content {
            background-color: $bdl-gray-30;
        }
    }
}
