@import '../variables';

.be .be-header {
    display: flex;
    flex: 0 0 70px;
    align-items: center;
    padding: 0 25px 0 0;
    background: $almost-white;
    border-bottom: 1px solid $bdl-gray-10;

    .be-is-small & {
        padding-right: 20px;
    }

    .be-search {
        flex: 1;
        padding-left: 20px;
    }

    input[type='search'] {
        width: 100%;
        font: inherit;
        -webkit-appearance: none;
    }
}
