// Z-Indexes
$modal-dialog-z-index: 160 !default;
$menu-z-index: 170 !default;
$notifications-wrapper-z-index: 180 !default;
$overlay-z-index: 190 !default;
$tooltip-z-index: 190 !default;

// Borders
$bdl-border-radius-size: 4px;
$bdl-border-radius-large-size: 10px; // Slowly migrating certain components, such as Classification Labels, to this larger radius

// Spacing
$bdl-grid-unit: 4px !default;
$bdl-grid-unit-px: 4 !default; // unitless grid unit (defined in pixels for manual multiplication in JS)
