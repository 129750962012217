@import '../../styles/variables';

.submenu-target {
    $bdl-submenu-offset: $bdl-grid-unit * -3;

    position: relative;

    .submenu {
        position: absolute;
        top: $bdl-submenu-offset;
        right: auto;
        left: calc(100% - #{$bdl-submenu-offset});

        &.is-left-aligned {
            right: 100%;
            left: auto;
        }

        &.is-hidden {
            right: auto;
            left: -9999px;
        }

        &.is-bottom-aligned {
            top: auto;
            bottom: $bdl-submenu-offset;
        }
    }

    .menu-item-arrow {
        position: absolute;
        top: $bdl-grid-unit * 3;
        right: $bdl-grid-unit * 2;
    }
}
