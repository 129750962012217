@import '../../styles/variables';
@import './mixins';

$badgeable-position-offset: 1px;
$badgeable-icon-size: 14px;

$badgeable-position: ($badgeable-icon-size/2);

%badge {
    position: absolute;
    pointer-events: all;
}

.badgeable-container {
    position: relative;
    display: inline-block;
    line-height: 1;

    .badges {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    @include Badgeable-topLeftBadge {
        @extend %badge;

        top: -$badgeable-position + $badgeable-position-offset;
        left: $badgeable-position + $badgeable-position-offset;
        transform: translateX(-100%);
    }

    @include Badgeable-topRightBadge {
        @extend %badge;

        top: -$badgeable-position + $badgeable-position-offset;
        left: calc(100% - #{$badgeable-position + $badgeable-position-offset});
    }

    @include Badgeable-bottomLeftBadge {
        @extend %badge;

        bottom: -$badgeable-position + $badgeable-position-offset;
        left: $badgeable-position + $badgeable-position-offset;
        transform: translateX(-100%);
    }

    @include Badgeable-bottomRightBadge {
        @extend %badge;

        bottom: -$badgeable-position + $badgeable-position-offset;
        left: calc(100% - #{$badgeable-position + $badgeable-position-offset});
    }
}
