@import '../../styles/variables';

/**************************************
 * Badges
 **************************************/

.badge {
    display: inline-block;
    padding: 2px 4px 3px;
    color: $bdl-gray-62;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-decoration: none;
    background: $bdl-gray-10;
    border-radius: 3px;
}

.badge-info {
    color: $white;
    background: $bdl-box-blue-50;
}

.badge-warning {
    color: $white;
    background: $bdl-yellorange;
}

.badge-highlight {
    color: $white;
    background: $bdl-light-blue;
}

.badge-alert,
.badge-error {
    color: $white;
    background: $bdl-watermelon-red;
}

.badge-success {
    color: $white;
    background: $bdl-green-light;
}
