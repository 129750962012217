@import '../variables';

/**
 * Mixin to make standard the style for selected items in flyout lists
 * Example:
 * - data list components
 */
@mixin bdl-Overlay-listItemContainer {
    padding: ($bdl-grid-unit * 2) ($bdl-grid-unit * 12) ($bdl-grid-unit * 2) ($bdl-grid-unit * 2);
    border-radius: $bdl-border-radius-size * 2;
    cursor: pointer;

    &.is-active {
        background-color: fade-out($bdl-gray, .95);
    }
}

/**
 * Mixin to make standard the container treatment for any overlapping containers (Overlays)
 * Component Examples:
 * - Flyout
 * - Overlay
 * - Pill Selector Dropdown
 * Feature Examples:
 * - Header Flyout
 */
@mixin bdl-Overlay-container {
    margin-top: $bdl-grid-unit;
    padding: $bdl-grid-unit * 3;
    background-color: $white;
    border: 1px solid $bdl-gray-10;
    border-radius: $bdl-border-radius-size * 2;

    @include bdl-Overlay-containerShadow;
}

/**
 * Mixin to make standard the shadow treatment for any overlapping containers
 * Example:
 * - custom flyouts with different border designs
 * - tooltips
 * - FTUX tooltip guides
 */
@mixin bdl-Overlay-containerShadow {
    box-shadow: 0 $bdl-grid-unit ($bdl-grid-unit * 3) 0 rgba($black, .1);
}
