@import '../../styles/variables';

@mixin bdl-SecurityBadge {
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 2px 8px;
    border-radius: $bdl-border-radius-large-size;
}

@mixin bdl-SecurityBadge-name {
    margin-left: $bdl-grid-unit;
    font-weight: bold;
    font-size: 10px !important; // To inhibit overriding
    line-height: 16px;
}
