@import '../../styles/variables';

/**************************************
 * Radio
 **************************************/

.radio-label {
    position: relative;
    display: inline-flex;
    align-items: center;
    user-select: none;

    > input[type='radio'] {
        flex: none;
        width: 14px;
        height: 14px;
        margin: 0;
        opacity: 0;

        + span::before {
            position: absolute;
            top: 3px;
            left: 0;
            width: 14px;
            height: 14px;
            background-color: $white;
            border: 7px solid $white;
            border-radius: 9001px;
            box-shadow: 0 0 0 1px $bdl-gray-50, 0 -1px 1px rgba(0, 0, 0, .4);
            transition: border-width .1s;
            content: '';
        }

        &:checked + span::before {
            background-color: $primary-color;
            border: 3px solid $white;
        }

        &:focus + span::before {
            box-shadow: 0 0 0 1px $primary-color;
        }

        &:disabled + span::before {
            background-color: $bdl-gray-30;
            border-color: $bdl-gray-02;
            box-shadow: 0 0 0 1px $bdl-gray-20;
        }

        + span + span {
            margin-left: 10px;
        }

        &:disabled + span + span {
            color: $bdl-gray-30;
        }
    }
}

.radio-container {
    margin: 0 0 20px;
}

.radio-description {
    margin-top: 2px;
    margin-left: 24px;
    color: $bdl-gray-50;
}
