@import '../../styles/variables';
$radar-animation-offset: 1px;

.radar {
    position: relative;

    .radar-dot::before {
        position: absolute;
        top: -4px;
        left: -4px;
        z-index: $overlay-z-index + 3;
        width: 8px;
        height: 8px;
        background-color: $bdl-box-blue;
        border: 2px solid $white;
        border-radius: 50%;
        content: ' ';
    }

    .radar-dot::after {
        position: absolute;
        z-index: $overlay-z-index + 2;
        width: 10px;
        height: 10px;
        background-color: $bdl-box-blue;
        border-radius: 50%;
        box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
        animation-name: ripple;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        content: ' ';
    }

    .radar-circle::before {
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: $overlay-z-index + 2;
        width: 40px;
        height: 40px;
        border: 1px solid $bdl-box-blue;
        border-radius: 50%;
        content: ' ';
    }

    .radar-circle::after {
        position: absolute;
        top: -20px;
        left: -20px;
        z-index: $overlay-z-index + 2;
        width: 40px;
        height: 40px;
        background-color: $bdl-box-blue;
        border-radius: 50%;
        opacity: .3;
        content: ' ';
    }

    @keyframes ripple {
        0% {
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            opacity: .75;
        }

        100% {
            top: -20px;
            left: -20px;
            width: 40px;
            height: 40px;
            opacity: 0;
        }
    }

    .radar-animation-target-attached-bottom.radar-animation-target-attached-center > & {
        margin-top: $radar-animation-offset;
    }

    .radar-animation-target-attached-bottom.radar-animation-target-attached-left > & {
        margin-top: $radar-animation-offset;
    }

    .radar-animation-target-attached-bottom.radar-animation-target-attached-right > & {
        margin-top: $radar-animation-offset;
    }

    .radar-animation-target-attached-middle.radar-animation-target-attached-right > & {
        margin-left: $radar-animation-offset;
    }

    .radar-animation-target-attached-middle.radar-animation-target-attached-center > & {
        margin-top: $radar-animation-offset;
    }

    .radar-animation-target-attached-middle.radar-animation-target-attached-left > & {
        margin-right: $radar-animation-offset;
    }

    .radar-animation-target-attached-top.radar-animation-target-attached-center > & {
        margin-bottom: $radar-animation-offset;
    }

    .radar-animation-target-attached-top.radar-animation-target-attached-left > & {
        margin-bottom: $radar-animation-offset;
    }

    .radar-animation-target-attached-top.radar-animation-target-attached-right > & {
        margin-bottom: $radar-animation-offset;
    }
}

.radar-animation-element {
    z-index: $overlay-z-index;
}
