.bcu-droppable-content {
    position: relative;
    display: flex;
    flex: 1 0 200px;
    flex-direction: column;

    .bcu-item-list {
        position: relative;
        flex: 1;

        // sass-lint:disable class-name-format
        .ReactVirtualized__Table__Grid {
            outline: none;
        }
    }
}
