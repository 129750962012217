@import '../../../styles/variables';

.bdl-SecurityControlsItem {
    color: $bdl-gray-80;
    font-size: 12.5px;
    font-style: italic;

    &::before {
        display: inline-block;
        width: 1em;
        color: $bdl-gray-30;
        font-weight: bold;
        font-style: normal;
        content: '\2022';
    }
}

.bdl-SecurityControlsItem-tooltipContent {
    p:last-child {
        margin-bottom: 0;
    }
}

.bdl-SecurityControlsItem-tooltip {
    max-width: 270px;
}

.bdl-SecurityControlsItem-tooltipIcon {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 3px;
    vertical-align: middle;
    cursor: pointer;
}
