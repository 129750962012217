@import './variables';
@import '../../styles/common/buttons';
@import '../../styles/common/inline-notifications';
@import '../../styles/modifiers/accessibility';
@import '../../styles/common/overlay';
@import '../../styles/common/pills';
@import '../../components/pill-selector-dropdown/PillSelector';
@import '../../components/notification/Notification';

@mixin be-content {
    color: $bdl-gray;
    line-height: 20px;
    letter-spacing: .3px;

    /* stylelint-disable */
    ::-webkit-input-placeholder {
        @include placeholder;
    }

    ::-moz-placeholder {
        @include placeholder;
    }

    :-ms-input-placeholder {
        @include placeholder;
    }

    ::-webkit-file-upload-button {
        cursor: pointer;
    }
    /* stylelint-enable */

    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
        }
    }

    .bdl-SelectButton,
    .btn-plain,
    input,
    textarea,
    select,
    .select-button {
        font: inherit;
    }
}

.be {
    @include reset;

    // links have tag styles
    @import '../../styles/common/links';
    // Forms have tag styles
    @import '../../styles/common/forms';

    @include be-content;
    /* stylelint-disable declaration-no-important */
    box-sizing: border-box !important;
    width: 100%;
    /* stylelint-enable declaration-no-important */
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: $white;
    border: 0 none;
    outline: none;

    .ReactModal__Body--open & {
        position: relative;
    }
}

.be-modal {
    // This class can be used to apply the base content reset to portalled elements are outside of the .be root
    // Also add the .be class to the inner content to get the rest of the styles
    @include reset;
    @include be-content;
}

.be-app-element {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

// Input element styled like a link
.be-input-link {
    display: block;
    color: $blue;
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;
    }

    input {
        display: none;
    }
}

// Input styled like a button
.be-input-btn {
    display: block;
    cursor: pointer;

    input {
        display: none;
    }
}

.be-hidden {
    display: none;
}
