@import '../common/variables';

.bcs-section-content {
    padding: 15px 0 5px;
}

.bcs-section {
    margin: 0 8px 20px 25px;
}

.be .btn-plain.bcs-section-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0 10px;
    color: initial;
    text-align: left;
    border-bottom: 1px solid $bdl-gray-10;

    .icon-caret-down {
        @include bdl-transition($bdl-transitionDurationBase, transform);
    }

    &:active,
    &:focus {
        text-decoration: none;
        border-bottom-color: $blue;

        svg path {
            fill: $bdl-gray;
        }
    }
}

.be .bcs-section-open .btn-plain.bcs-section-title .icon-caret-down {
    transform: rotateZ(180deg);
}
