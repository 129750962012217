@import '../common/variables';

.bcpr {
    .bcpr-notification {
        position: absolute;
        width: 100%;

        .notification > svg {
            display: none;
        }
    }
}
