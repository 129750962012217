@import '../variables';

.be-item-label {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    outline: none;
}
