@import '../constants/colors';
@import '../constants/typography';

@mixin font-family {
    font-family: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin font-aliasing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@mixin fonts {
    @include font-family;
    @include font-aliasing;

    font-weight: normal;
    font-size: $bdl-fontSize;
}

@mixin bdl-fonts-dejaBlue {
    @include font-family;
    @include font-aliasing;

    font-weight: normal;
    font-size: $bdl-fontSize--dejaBlue;
}

@mixin common-typography {
    @include fonts;

    color: $bdl-gray;
    line-height: $bdl-line-height;
    letter-spacing: .3px;
}

@mixin bdl-commonTypography-dejaBlue {
    @include bdl-fonts-dejaBlue;

    color: $bdl-gray;
    line-height: $bdl-line-height;
    letter-spacing: .3px;
}
