@mixin bdl-SidebarNavButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px; // 1px for left border
    height: 60px;
    background-color: transparent;
}

@mixin bcs-SidebarSection {
    margin: 0 8px 20px 25px;

    &:empty {
        margin: 0;
    }
}
