@import '../variables';

.be-breadcrumb {
    display: flex;
    align-items: center;
    min-width: 20px;

    &:only-child {
        min-width: 64px;
    }

    .be & .btn-plain {
        color: $bdl-gray-62;

        &:last-child {
            color: $bdl-gray;
        }
    }
}

.be-breadcrumb-seperator {
    flex-shrink: 0;
    margin: 1px 8px 0 5px;
}

.bdl-Breadcrumb-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
