@import '../../styles/variables';

.bdl-ReadOnlyMetadataField {
    margin: 0 0 20px;
    word-wrap: break-word;

    &:last-child {
        margin-bottom: 0;
    }

    .bdl-CustomMetadataField:not(:last-child) & {
        margin-bottom: 20px;
    }

    dt {
        color: $bdl-gray-62;
    }

    dd {
        line-height: 16px;
    }

    .bdl-ReadOnlyMetadataField-desc {
        color: $bdl-gray-62;
    }
}
