@import 'variables';

@mixin box-inputs {
    padding: 7px;
    color: $bdl-gray;
    border: 1px solid $bdl-gray-20;
    border-radius: 3px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .1);
    transition: border-color linear .15s, box-shadow linear .1s;
    -webkit-font-smoothing: antialiased;
}

@mixin box-inputs-hover {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .15);
}

@mixin box-inputs-focus {
    border: 1px solid $primary-color;
    outline: 0;
    box-shadow: none;
}
