@import '../../styles/variables';
@import '../../styles/inputs';

$font: Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$minimumWidth: 300px;
$sidebarContentWidth: 340px;

@mixin rendering {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

@mixin reset {
    @include rendering;

    /* stylelint-disable declaration-no-important */
    box-sizing: border-box !important;
    font-size: 13px;
    /* stylelint-enable declaration-no-important */
    font-family: $font;

    * {
        @include rendering;

        /* stylelint-disable declaration-no-important */
        box-sizing: border-box !important;
        /* stylelint-enable declaration-no-important */
        font-family: inherit;

        &::after,
        &::before {
            box-sizing: inherit;
            color: inherit;
            font-family: inherit;
            text-rendering: inherit;
        }
    }
}

@mixin placeholder {
    font-weight: normal;
    font-family: $font;
    opacity: .6;
}
