@import '../../styles/variables';

.bdl-SidebarToggleButton {
    margin: 0 3px;
    padding: 4px;
    border-radius: $bdl-border-radius-size;

    path {
        fill: $bdl-gray-50;
    }

    &:not(.bdl-is-disabled):hover,
    &:not(.is-disabled):hover {
        background-color: $bdl-gray-05;
    }

    &:not(.bdl-is-disabled):focus,
    &:not(.is-disabled):focus {
        border-color: #96a0a6;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    }

    &.bdl-is-collapsed,
    &.bdl-is-collapsed:hover {
        background-color: $bdl-box-blue;

        path {
            fill: $white;
        }
    }
}
